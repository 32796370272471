import React, {FC} from "react"

import PageContainer from "../../page"
import BigTitle from "../title"

const Page: FC = () => {
  return (
    <PageContainer>
      <BigTitle>
        Présentation de la formation distancielle <br />
        Cap Bien-être
      </BigTitle>
    </PageContainer>
  )
}

export default Page
