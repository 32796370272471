import React, {FC} from "react"

import PageContainer from "../../page"
import BigTitle from "../title"

const Page: FC = () => {
  return (
    <PageContainer>
      <BigTitle>
        Accéder au questionnaire
        <br />« Retours sur le distanciel »
      </BigTitle>
    </PageContainer>
  )
}

export default Page
